import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { DeleteForever } from "@material-ui/icons";
import { Link } from "react-router-dom";

import * as TableStyles from "../Table.styles";
import { Car } from "../Car";

interface Props {
  car: Car
  handleDelete: (car: Car) => void
  handleChange: (car: Car) => void
}

const CarArea = (props: Props): JSX.Element => {

  const { car } = props;

  const handleDelete = () => {
    props.handleDelete(car);
  };

  return(
    <TableRow key={car.id}>
      <TableCell>
        <Link to={`/cars/${car.id}`}>
          <img src={car.photos[0]}/>
        </Link>
      </TableCell>
      <TableCell>{car.productionDate.getFullYear()}</TableCell>
      <TableCell>{car.engine}</TableCell>
      <TableCell>
        <TableStyles.DeleteButton
          onClick={handleDelete}>
          <DeleteForever/>
        </TableStyles.DeleteButton>
      </TableCell>
    </TableRow>
  );
};

export { CarArea };