import { useState } from "react";
import { User } from "../users/User";
import * as Styled from "./UserDataForm.styles";
import { StylesProvider } from "@material-ui/core/styles";

interface Props {
  user: User;
}

enum Mode { DISPLAY, EDIT }

const UserDataForm = (props: Props): JSX.Element => {

  const { user } = props;
  const [mode, setMode] = useState<Mode>(Mode.DISPLAY);
  const [data, setData] = useState<User>(user);
  const [editData, setEditData] = useState<User>(data);
  const placeholderImage = "https://upload.wikimedia.org/wikipedia/commons/thumb/6/65/No-Image-Placeholder.svg/1200px-No-Image-Placeholder.svg.png";

  const handleFieldChange = (field: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setEditData({ ...editData, [field]: event.target.value });
  };

  const handleSave = () => {
    setData(editData);
    setMode(Mode.DISPLAY);
  };

  const handleCancel = () => {
    setEditData(data);
    setMode(Mode.DISPLAY);
  };

  const handleEdit = () => {
    setMode(Mode.EDIT);
  };

  return(
    mode == Mode.DISPLAY
      ? (
        <StylesProvider injectFirst>
          <Styled.AccDataContainer>
            <Styled.FormHeading>
              Account data
            </Styled.FormHeading>
            <Styled.RowContainer>
              <Styled.TextFieldsContainer>
                <Styled.TextField
                  disabled
                  label="First name"
                  value={data.firstName}/>
                <Styled.TextField
                  disabled
                  label="Last name"
                  value={data.lastName}/>
                <Styled.TextField
                  disabled
                  label="E-mail"
                  value={data.email}/>
                <Styled.TextField
                  disabled
                  label="Phone number"
                  value={data.phoneNumber}/>
                <Styled.TextField
                  disabled
                  label="Photo link"
                  value={data.photo}/>
              </Styled.TextFieldsContainer>
              <Styled.Photo src={ data.photo ? data.photo : placeholderImage }/>
            </Styled.RowContainer>
            <Styled.Button
              variant="contained"
              color="primary"
              onClick={handleEdit}>
              Edit
            </Styled.Button>
          </Styled.AccDataContainer>
        </StylesProvider>
      )
      : (
        <StylesProvider injectFirst>
          <Styled.AccDataContainer>
            <Styled.FormHeading>
              Account data
            </Styled.FormHeading>
            <Styled.RowContainer>
              <Styled.TextFieldsContainer>
                <Styled.TextField
                  label="First name"
                  onChange={handleFieldChange("firstName")}/>
                <Styled.TextField
                  label="Last name"
                  onChange={handleFieldChange("lastName")}/>
                <Styled.TextField
                  label="E-mail"
                  onChange={handleFieldChange("email")}/>
                <Styled.TextField
                  label="Phone number"
                  onChange={handleFieldChange("phoneNumber")}/>
                <Styled.TextField
                  label="Photo link"
                  onChange={handleFieldChange("photo")}/>
              </Styled.TextFieldsContainer>
              <Styled.Photo src={ data.photo ? data.photo : placeholderImage }/>
            </Styled.RowContainer>
            <Styled.RowContainer>
              <Styled.Button
                variant="contained"
                color="primary"
                onClick={handleSave}>
                Save
              </Styled.Button>
              <Styled.Button
                variant="contained"
                color="primary"
                onClick={handleCancel}>
                Cancel
              </Styled.Button>
            </Styled.RowContainer>
          </Styled.AccDataContainer>
        </StylesProvider>
      )
  );
};

export { UserDataForm };