import { DriveEta, EventNote, Person} from "@material-ui/icons";
import HttpsRoundedIcon from "@material-ui/icons/HttpsRounded";

import * as Styled from "./AppMenu.styles";


const AppMenu = (): JSX.Element => {
  return (
    <Styled.MenuBar>
      <Styled.MenuList>
        <Styled.MenuElement to="/models">
          <Styled.MenuIcon>
            <DriveEta fontSize="large"/>
          </Styled.MenuIcon>
            Cars
        </Styled.MenuElement>
        <Styled.MenuElement to="/test-drives">
          <Styled.MenuIcon>
            <EventNote fontSize="large"/>
          </Styled.MenuIcon>
            Test Drives
        </Styled.MenuElement>
        <Styled.MenuElement to="/users">
          <Styled.MenuIcon>
            <Person fontSize="large"/>
          </Styled.MenuIcon>
            Users
        </Styled.MenuElement>
        <Styled.MenuElementToRight to="/authorization">
          <Styled.MenuIcon>
            <HttpsRoundedIcon fontSize = "large"/>
          </Styled.MenuIcon>
           Login
        </Styled.MenuElementToRight>
      </Styled.MenuList>
    </Styled.MenuBar>
  );
};

export { AppMenu };