import styled from "styled-components";
import { TextField, Button } from "@material-ui/core";

const AccDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 550px;
  max-height: 550px;
  padding: 40px;
  margin: 40px;
  justify-content: left;
  border: 3px solid ${({ theme }) => theme.palette.primary.main};
  box-shadow: 4px 4px #BBB
`;

const FormHeading = styled.p`
  color: #666;
	font-weight: 300;
	font-size: 32px;
	letter-spacing: 3px;
	margin: 0px;
`;

const TextFieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 45%;
`;

const RowContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledTextField = styled(TextField)`
  margin-top: 20px;
  width: 100%;
`;

const Photo = styled.img`
  max-width: 45%;
  max-height: 360px;
  border: 1px solid;
`;

const StyledButton = styled(Button)`
  margin: 40px 20px 0 20px;
  width: 40%;
`;

export { 
  AccDataContainer, 
  FormHeading, 
  TextFieldsContainer, 
  RowContainer, 
  StyledTextField as TextField, 
  Photo,
  StyledButton as Button
};