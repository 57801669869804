import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { Link } from "react-router-dom";
import { User } from "../users/User";
import { Car } from "../Car";
import { format } from "date-fns";
//import { TestDrive } from "../test_drives/TestDrive";

export interface TestDrive {
  id: number;
  user: User;
  car: Car;
  date: Date;
}

interface Props {
  testDrive: TestDrive;
}

const UserTestDriveArea = (props: Props): JSX.Element => {

  const { testDrive } = props; 

  return (
    <TableRow key={testDrive.id}>
      <TableCell>
        <Link to={`/cars/${testDrive.car.id}`}>
          {`${testDrive.car.model.manufacturer} ${testDrive.car.model.modelName}, ${testDrive.car.productionDate.getFullYear()}`}
        </Link>
      </TableCell>
      <TableCell>{`${format(testDrive.date, "H:m, d.M.y")}`}</TableCell>
    </TableRow>
  );
};

export { UserTestDriveArea };