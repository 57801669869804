import styled from "styled-components";
import TextField from "@material-ui/core/TextField";
import Link from "@material-ui/core/Link";

const LoginFormContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const RegisterFormContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const RegisterDataContainer = styled.div`
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
const Text = styled(TextField)`
    margin: 5px;
`;

const Container = styled.div`
    margin: 5px;
`;

const TextLink = styled(Link)`
    text-align: center;
    cursor: pointer;
`;
export { Text, LoginFormContainer, Container, TextLink, RegisterFormContainer, RegisterDataContainer};