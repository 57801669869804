import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
//import { TestDrive } from "../test_drives/TestDrive";
import { TestDrive, UserTestDriveArea } from "./UserTestDriveArea";
import * as Styled from "./UserTestDrivesList.styles";

interface Props {
  testDrives: TestDrive[]
}

const UserTestDrivesList = (props: Props): JSX.Element => {
  return(
    <Styled.TestDrivesContainer>
      <Styled.TableHeading>
        Your test drives
      </Styled.TableHeading>
      <Styled.TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Car</TableCell>
              <TableCell>Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {props.testDrives.map((row) => (
              <UserTestDriveArea
                key={row.id}
                testDrive={row}
              />
            ))}
          </TableBody>
        </Table>
      </Styled.TableContainer>
    </Styled.TestDrivesContainer>
  );
};

export { UserTestDrivesList };