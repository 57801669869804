import { useState } from "react";
import { Edit, DeleteForever } from "@material-ui/icons";

import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import * as TableStyles from "../Table.styles";
import { User } from "./User";
import { UserModal } from "./UserModal";

interface Props {
  user: User
  handleDelete: (user: User) => void
  handleChange: (user: User) => void
}

const UserArea = (props: Props): JSX.Element => {

  const { user } = props;

  const [modalEditOpen, setModalEditOpen] = useState<boolean>(false);

  const handleDelete = () => {
    props.handleDelete(user);
  };

  const handleEdit = () => {
    //edit pop up
    setModalEditOpen(false);
  };

  const handleCloseEditModal = () => {
    setModalEditOpen(false);
  };

  return(
    <TableRow key={user.id}>
      <TableCell><TableStyles.UserPhoto src={user.photo}/></TableCell>
      <TableCell>{user.firstName}  {user.lastName}</TableCell>
      <TableCell>{user.phoneNumber}</TableCell>
      <TableCell>{user.email}</TableCell>
      <TableCell>
        <TableStyles.EditButton
          onClick={() => setModalEditOpen(true)}>
          <Edit/>
        </TableStyles.EditButton>
        <TableStyles.DeleteButton
          onClick={handleDelete}>
          <DeleteForever/>
        </TableStyles.DeleteButton>
      </TableCell>
      <UserModal 
        open={modalEditOpen}
        label={"Edit User"}
        user = {{
          id: user.id,
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
          phoneNumber: user.phoneNumber,
          photo: user.photo,
        }}
        handleApply={handleEdit}
        handleClose={handleCloseEditModal}
      />
    </TableRow>
  );
};

export { UserArea };