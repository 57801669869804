import { Button, CircularProgress } from "@material-ui/core";
import styled from "styled-components";

const ModelDataContainer = styled.div`
  margin: 25px 25px;
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
`;

const ManufacturerText = styled.h1`
  color: ${({theme}) => theme.palette.primary.main};
  margin: 0px;
`;

const ModelText = styled.h2`
  margin: 0px;
`;

const CarDetailsContainer = styled.div`
  margin: 10px 10px;
  padding: 20px 20px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border-bottom: 2px solid ${({theme}) => theme.palette.outline.grey};
`;

const CarDataContainer = styled.div`
  flex: 2
`;

const CarDataHeading = styled.div`
  margin: 10px 15px;
  flex: 1;
  color: #666;
  font-weight: 300;
  font-size: 34px;
  letter-spacing: 3px;
  border-bottom: 2px solid ${({theme}) => theme.palette.outline.grey};
`;

const CarData = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const CarDataTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 5px 5px;
  margin: 10px 10px;
  background: ${({theme}) => theme.palette.background.default};
`;

const CarDataText = styled.div`
  flex: 1;
  margin: 5px 0px;
`;

const CarDataSubText = styled.div`
  flex: 1;
  margin: 5px 10px;
`;

const CarDescriptionText = styled.div`
  flex: 1;
  padding: 5px 5px;
  margin: 10px 10px;
`;

const CarDataEditText = styled.div`
  flex: 1;
  margin: 5px 0px;
`;

const CarDataSubEditText = styled.div`
  flex: 1;
  margin: 5px 10px;
`;

const CarDescriptionEditText = styled.div`
  flex: 1;
  padding: 0px 5px;
  margin: 5px 10px;
`;

const CarDataEditSelect = styled.div`
  flex: 1;
  margin: 0px -8px;
`;

const CarPhotosContainer = styled.div`
  flex: 1;
`;

const CarPhotosHeading = styled.div`
  margin: 10px 15px;
  flex: 1;
  color: #666;
  font-weight: 300;
  font-size: 34px;
  letter-spacing: 3px;
  border-bottom: 2px solid ${({theme}) => theme.palette.outline.grey};
`;

const PhotosContainer = styled.div`
`;

const OptionsContainer = styled.div`
  margin: 10px 10px;
  display: flex;
  gap: 10px;
`;

const StyledButton = styled(Button)`
`;

const Loading = styled(CircularProgress)`
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -75px;
`;

export {
  ModelDataContainer,
  ManufacturerText,
  ModelText,
  CarDetailsContainer,
  CarDataContainer,
  CarDataHeading,
  CarData,
  CarDataTextContainer,
  CarDataText,
  CarDataSubText,
  CarDescriptionText,
  CarDataEditText,
  CarDataSubEditText,
  CarDescriptionEditText,
  CarDataEditSelect,
  CarPhotosContainer,
  CarPhotosHeading,
  PhotosContainer,
  OptionsContainer,
  StyledButton,
  Loading
};