/* eslint-disable @typescript-eslint/ban-types */
import { TestDrive } from "./TestDrive";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import * as TableStyles from "../Table.styles";
import { Edit, DeleteForever } from "@material-ui/icons";
import { Link } from "react-router-dom";
import { TestDriveModal } from "./TestDriveModal";
import { useState } from "react";
import { format } from "date-fns";

interface Props {
  testDrive: TestDrive
  handleDelete: Function
  handleChange: Function
}

const TestDriveArea = (props: Props): JSX.Element => {
  const { testDrive } = props;

  const [modalEditOpen, setModalEditOpen] = useState<boolean>(false);

  const handleDelete = () => {
    props.handleDelete(testDrive);
  };

  const handleEdit = () => {
    //edit pop up
    setModalEditOpen(false);
  };

  const handleCloseEditModal = () => {
    setModalEditOpen(false);
  };

  return(
    <TableRow key={testDrive.id}>
      <TableCell>
        <Link to={`/cars/${testDrive.id}`}>
          {`${testDrive.car.model.manufacturer}, ${testDrive.car.model.modelName}`}
        </Link>
      </TableCell>
      <TableCell>{`${testDrive.user.firstName} ${testDrive.user.lastName}`}</TableCell>
      <TableCell>{`${format(testDrive.date, "H:m, d.M.y")}`}</TableCell>
      <TableCell>
        <TableStyles.EditButton
          onClick={() => setModalEditOpen(true)}>
          <Edit/>
        </TableStyles.EditButton>
        <TableStyles.DeleteButton
          onClick={handleDelete}>
          <DeleteForever/>
        </TableStyles.DeleteButton>
      </TableCell>
      <TestDriveModal
        open={modalEditOpen}
        label={"Edit test drive"}
        testDrive={{
          id: testDrive.id,
          car: testDrive.car,
          user: testDrive.user,
          date: testDrive.date
        }}
        handleApply={handleEdit}
        handleClose={handleCloseEditModal}
      />
    </TableRow>
  );
};

export { TestDriveArea };