import React, { useState } from "react";
import { makeStyles} from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";

import { useHistory } from "react-router-dom";
import LoginForm from "./LoginForm";
import RegisterForm from "./RegisterForm";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    width: 500,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));

const Authorization = (): JSX.Element => {
  const classes = useStyles();
  const history = useHistory();
  const [isOpenLoginWindow,setIsOpenLoginWindow] = useState(true);
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = useState(getModalStyle);
  const [open,setOpen] = useState(true);
  const handleClose = () => {
    setOpen(false);
    history.goBack();
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <div style={modalStyle} className={classes.paper}>
          {  isOpenLoginWindow ? 
            <LoginForm setIsOpenLoginWindow = {setIsOpenLoginWindow} handleClose = {handleClose}></LoginForm> :
            <RegisterForm setIsOpenLoginWindow = {setIsOpenLoginWindow}></RegisterForm>
          }
        </div>
      </Modal>
    </div>
  );
};

export {Authorization};