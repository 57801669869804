import { useQuery, UseQueryResult } from "react-query";

import { getModels, getModel } from "../fetches/get";
import { useFetch } from "../../components/providers/FetchProvider";
import { useMutation, UseMutationOptions, UseMutationResult } from "react-query";
import { Model } from "../../types/models";
import { deleteModel } from "../fetches/delete";
import { patchModel } from "../fetches/patch";
import { postAddModel } from "../fetches/post";

const useModelsQuery = ()
: UseQueryResult<Model[], unknown> => {
  const { fetch } = useFetch();
  return useQuery("models", () => getModels(fetch));
};

const useModelQuery = (id: number)
: UseQueryResult<Model, unknown> => {
  const { fetch } = useFetch();
  return useQuery(["models", id], () => getModel(fetch, id));
};

const useDeleteModelMutation = (options: UseMutationOptions<unknown, Error, Model>)
: UseMutationResult<unknown, Error, Model> => {
  const { fetch } = useFetch();
  return useMutation("models", (values: Model) => deleteModel(fetch, values), options);
};

const usePatchModelMutation = (options: UseMutationOptions<unknown, Error, Model>)
: UseMutationResult<unknown, Error, Model> => {
  const { fetch } = useFetch();
  return useMutation("models", (values: Model) => patchModel(fetch, values), options);
};

const useAddModelMutation = (options: UseMutationOptions<unknown, Error, Model>)
: UseMutationResult<unknown, Error, Model> => {
  const { fetch } = useFetch();
  return useMutation("models", (values: Model) => postAddModel(fetch, values), options);
};

export { useModelsQuery,
  useModelQuery,
  useDeleteModelMutation,
  usePatchModelMutation,
  useAddModelMutation
};