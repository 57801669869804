import { AxiosInstance } from "axios";
import { Model } from "../../types/models";

const deleteModel = async (
  instance: AxiosInstance,
  values: Model
): Promise<unknown> => {
  const { data } = await instance.delete(`/models/${values.id}`);
  return data;
};

export { deleteModel };