import styled from "styled-components";
import TableContainer from "@material-ui/core/TableContainer";
import TableCell from "@material-ui/core/TableCell";

const UserPhoto = styled.img`
  width: 150px;
  height: 150px;
`;

const TableHeadRow = styled(TableCell)`
  font-weight: 300;
	font-size: 22px;
	letter-spacing: 2px;
`;

const StyledTable = styled(TableContainer)`
  margin: 30px 0;
`;

const EditButton = styled.button`
  margin: 5px;
`;

const DeleteButton = styled.button`
  margin: 5px;
`;

export { UserPhoto, StyledTable, TableHeadRow, EditButton, DeleteButton };