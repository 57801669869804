import styled from "styled-components";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";

const FilterButton = styled(Button)`
	margin: 20px 0;
	padding: 6px 40px;
`; 

const TextfieldContainer = styled.div`
	display: flex;
	margin: 20px 0;
	justify-content: space-between;
`;

const ManufacturerHeading = styled.p`
	color: ${({theme}) => theme.palette.primary.main};
	font-weight: 600;
	font-size: 34px;
	letter-spacing: 3px;
	margin: 0px;
`;

const ModelHeading = styled.p`
	color: #666;
	font-weight: 300;
	font-size: 34px;
	letter-spacing: 3px;
	margin: 0;
`;

const FilterHeading = styled.p`
	color: #666;
	font-weight: 300;
	font-size: 34px;
	letter-spacing: 3px;
	margin-top: 40px;
`;

const ModelHeadingContainer = styled.div`
	margin-top: 40px;
`;

const FilterLine = styled.hr`
  border-top: 2px ${({theme}) => theme.palette.outline.grey};
`;

const YearRangeTextField = styled(TextField)`
	width: 90px;
	margin-top: 7px;
`;

const AddButton = styled.button`
	margin: 25px 10px;
	float: right;
	width: 40px;
`;

export { FilterLine, FilterHeading, TextfieldContainer, FilterButton, AddButton, ManufacturerHeading, ModelHeading, ModelHeadingContainer, YearRangeTextField };