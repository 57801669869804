import TableContainer from "@material-ui/core/TableContainer";
import styled from "styled-components";

const TestDrivesContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 600px;
  padding: 40px;
  margin: 40px;
  justify-content: left;
  border: 3px solid ${({ theme }) => theme.palette.primary.main};
  box-shadow: 4px 4px #BBB
`;

const TableHeading = styled.p`
  color: #666;
	font-weight: 300;
	font-size: 32px;
	letter-spacing: 3px;
	margin: 0px;
`;

const StyledTableContainer = styled(TableContainer)`
  margin: 20px 0;
`;

export {
  TestDrivesContainer,
  TableHeading,
  StyledTableContainer as TableContainer
};