/* eslint-disable @typescript-eslint/ban-types */
import React, { useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import { TextField } from "@material-ui/core";
import * as Styled from "../Modal.styles";
import { TestDrive } from "./TestDrive";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: "absolute",
      width: 400,
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  }),
);

interface TestDriveModalProps {
    open: boolean,
    label: string,
    testDrive: TestDrive,
    handleApply: (testDrive: TestDrive) => void,
    handleClose: () => void
}

const TestDriveModal = (props: TestDriveModalProps): JSX.Element => {
  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);
  const [testDrive, setTestDrive] = useState<TestDrive>(props.testDrive);

  const handleFieldChange = (field: string) => (e: React.ChangeEvent<HTMLInputElement>) => { 
    setTestDrive({...testDrive, [field]: e.target.value});
  };

  const handleApply = () => {
    props.handleApply(testDrive);
  };

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <h2>{props.label}</h2>
      <Styled.ModelModalData>
        <TextField 
          label="Car" 
          value={testDrive?.car.model.modelName}
          onChange={handleFieldChange("firstName")}>
        </TextField>
        <TextField 
          label="Person" 
          value={testDrive?.user.lastName}
          onChange={handleFieldChange("lastName")}>
        </TextField>
        <TextField 
          label="Date" 
          value={testDrive?.date.toString()}
          onChange={handleFieldChange("email")}>
        </TextField>
      </Styled.ModelModalData>
      <Styled.ModelModalButtons>
        <Styled.AddButton
          variant="contained"
          color="primary"
          onClick={handleApply}>
            Apply
        </Styled.AddButton>
        <Styled.CancelButton
          variant="contained"
          color="primary"
          onClick={() => props.handleClose()}>
            Cancel
        </Styled.CancelButton>
      </Styled.ModelModalButtons>
    </div>
  );

  return (
    <div>
      <Modal
        open={props.open}
        onClose={() => props.handleClose()}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  );
};

export { TestDriveModal };