import {
  createMuiTheme
} from "@material-ui/core/styles";

// declare module "styled-components/macro" {
//   export interface DefaultTheme extends Theme {}
// }

declare module "@material-ui/core/styles/createPalette" {
  interface Palette {
    outline: OutlinePaletteColor;
  }
  interface PaletteOptions {
    outline: OutlinePaletteColorOptions;
  }
  interface OutlinePaletteColor {
    grey: string
  }
  interface OutlinePaletteColorOptions {
    grey: string
  }
}

const customTheme = createMuiTheme({
  palette: {
    background: {
      default: "#fff",
    },
    primary: {
      main: "#3b1d8f",
    },
    outline: {
      grey: "#ddd"
    },
  },
});

export { customTheme };