import { Button } from "@material-ui/core";
import styled from "styled-components";

const ModelModalData = styled.div`
    display: flex;
    flex-direction: column;
    padding: 15px 10px;
    background: ${({theme}) => theme.palette.background.default};
    margin: 20px 15px;
`;

const ModelModalButtons = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
`;

const AddButton = styled(Button)`
`;

const CancelButton = styled(Button)`
`;

export { ModelModalData, ModelModalButtons, AddButton, CancelButton };
