import styled from "styled-components";

const RootContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  @media (max-width: 1200px) {
    flex-direction: column;
    align-items: center;
  }
`;

export { RootContainer };