import "date-fns";
import React from "react";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker
} from "@material-ui/pickers";

interface DatePickerProps{
    label: string
    date: Date
    handleDateChange: (date: Date) => void
}

const DatePicker = (props: DatePickerProps): JSX.Element =>{
  const [selectedDate, setSelectedDate] = React.useState<Date | null>(
    props.date
  );

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
    if(date !== null){
      props.handleDateChange(date);
    }
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <div>
        <KeyboardDatePicker
          disableToolbar
          variant="inline"
          format="dd/MM/yyyy"
          margin="normal"
          id="date-picker-inline"
          label={props.label}
          value={selectedDate}
          onChange={handleDateChange}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
        />
      </div>
    </MuiPickersUtilsProvider>
  );
};

export { DatePicker };