import { Edit, DeleteForever } from "@material-ui/icons";
import { useState } from "react";
import { Link } from "react-router-dom";

import { Model } from "../../types/models";
import * as Styled from "./ModelArea.styles";
import { ModelModal } from "./ModelModal";

interface Props {
    model: Model
    handleChange: (model: Model) => void
    handleDelete: (model: Model) => void
}

const ModelArea = (props: Props): JSX.Element => {
  const { model } = props;

  const [modalEditOpen, setModalEditOpen] = useState<boolean>(false);

  const handleDelete = () => {
    props.handleDelete(model);
  };

  const handleEdit = (model: Model) => {
    props.handleChange(model);
    setModalEditOpen(false);
  };

  const handleCloseEditModal = () => {
    setModalEditOpen(false);
  };

  return (
    <Styled.ModelContainer>
      <Link to={`/models/${model.id}`}>
        <img src={model.modelImageLink}/>
      </Link>
      <h4>{model.mark.markName}</h4>
      <h5>{model.modelName}</h5>
      <Styled.EditButton
        onClick={() => setModalEditOpen(true)}>
        <Edit/>
      </Styled.EditButton>
      <Styled.DeleteButton
        onClick={handleDelete}>
        <DeleteForever/>
      </Styled.DeleteButton>
      <ModelModal 
        open={modalEditOpen}
        label={"Edit Model"}
        model={{
          id: model.id,
          modelName: model.modelName,
          modelImageLink: model.modelImageLink,
          mark: model.mark
        }}
        handleApply={handleEdit}
        handleClose={handleCloseEditModal}
      />
    </Styled.ModelContainer>
  );
};
  
export { ModelArea };