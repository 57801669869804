import { Car } from "../Car";

export const isYearValid = (car: Car, filterData: Car, maxYear: number): boolean => {
  return car.productionDate.getFullYear() >= filterData.productionDate.getFullYear() && car.productionDate.getFullYear() <= maxYear;
};

export const isTypeValid = (car: Car, filterData: Car): boolean => {
  return car.carType.includes(filterData.carType);
};

export const isColorValid = (car: Car, filterData: Car): boolean => {
  return car.color.includes(filterData.color);
};

export const isEngineValid = (car: Car, filterData: Car): boolean => {
  return car.engine.includes(filterData.engine);
};

export const isEquipmentValid = (car: Car, filterData: Car): boolean => {
  return filterData.equipment.every(eq => car.equipment.includes(eq));
};