import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import React, {useState} from "react";
import * as Styled from "./Login.style";
import Button from "@material-ui/core/Button";
import Logo from "../../assets/logoMotorShow.png";
import axios from "axios";
import { validateEmail, validatePassword, validateInputLenght } from "./Validations";

interface Props {
    // eslint-disable-next-line @typescript-eslint/ban-types
    setIsOpenLoginWindow: Function
}


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {"& .MuiTextFiel+d-root": {
      margin: theme.spacing(1),
      width: 200
    },
    },
  }),
);
  
const RegisterForm = (props: Props): JSX.Element => {
  const classes = useStyles();
  const [firstname,setFirstname] = useState<string>("");
  const [lastname,setLastname] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [email,setEmail] = useState<string>("");
  const [password,setPassword] = useState<string>("");
  const [showErrorMessage,setShowErrorMessage] = useState(false);
  const [message,setMessage] = useState<string>();

  const userDTO = {
    email: "",
    password: "",
    phoneNumber: "",
    name: "",
    lastName: ""
  };
  
  const validateData = () => {
    if(validateInputLenght(firstname)&&validateInputLenght(lastname)&&validateEmail(email)&&validatePassword(password)){
      setShowErrorMessage(false);
      return true;
    }
    else{
      return false;
    }
  };
  
  const handleRegister = () =>{  
    if(validateData()){
      userDTO.email = email;
      userDTO.password = password;
      userDTO.name = firstname;
      userDTO.lastName = lastname;
      userDTO.phoneNumber = phoneNumber;
      axios
        .post("http://localhost:8080/api/client/",userDTO)
        .then(() => props.setIsOpenLoginWindow(true))
        .catch((error)=> 
        { 
          if(error.response?.status === 400){
            setMessage("Your email is allready in use.");
          }
          else if(error.response?.status > 400 || error.response === undefined)
            setMessage("Server problem occured. Please try later.");

          setShowErrorMessage(true);
        }
        );
    }
    else{
      setMessage("Incomplete data. Please check them one more time.");
      setShowErrorMessage(true);
    }
  };

  const showMessage =() =>{
    return (
      <h6 style = {{color: "red" , textAlign : "center"}}>{message}</h6>
    );
  };
 
  return (
    <form className={classes.root}>
      <Styled.RegisterFormContainer>
        <Styled.Container>
          <img src = {Logo}/>
        </Styled.Container>
        <Styled.RegisterDataContainer>
          <Styled.Container>
            <Styled.Text
              id="firstname-input"
              label="Firstname"
              variant="outlined"
              value = {firstname}
              onChange = {(e:React.ChangeEvent<HTMLInputElement>) => setFirstname(e.target.value)}
            />
          </Styled.Container>
          <Styled.Container>
            <Styled.Text
              id="lastname-input"
              label="Lastname"
              variant="outlined"
              value = {lastname}
              onChange = {(e:React.ChangeEvent<HTMLInputElement>) => setLastname(e.target.value)}
            />
          </Styled.Container>
        </Styled.RegisterDataContainer>
        <Styled.RegisterDataContainer>
          <Styled.Container>
            <Styled.Text
              error = {!validateEmail(email)}
              id="email-input-register"
              label="Email"
              variant="outlined"
              value = {email}
              onChange = {(e:React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
            />
          </Styled.Container>
          <Styled.Container>
            <Styled.Text
              error = {!validatePassword(password)}
              id="password-input-register"
              label="Password"
              variant="outlined"
              type="password"
              value = {password}
              onChange = {(e:React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
            />
          </Styled.Container>
        </Styled.RegisterDataContainer>
        <Styled.Container>
          <Styled.Text
            id="phone-number-input"
            label="Phone Number"
            variant="outlined"
            value = {phoneNumber}
            onChange = {(e:React.ChangeEvent<HTMLInputElement>) => setPhoneNumber(e.target.value)}
          />
        </Styled.Container>
        <Button variant="contained" color="primary" onClick = {handleRegister}>
             Register
        </Button>
        <Styled.Container>
          {showErrorMessage ? showMessage() : ""}
          <h6 style = {{textAlign: "center"}}> Already have an account ? <Styled.TextLink onClick = {() => props.setIsOpenLoginWindow(true)} >Sign in</Styled.TextLink> </h6>
        </Styled.Container>
      </Styled.RegisterFormContainer>
    </form>
  );
}; 
  
export default RegisterForm;