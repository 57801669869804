import { useQuery, UseQueryResult } from "react-query";

import { getCars, getCar } from "../fetches/get";
import { useFetch } from "../../components/providers/FetchProvider";
import { Car } from "../../types/cars";

const useCarsQuery = ()
: UseQueryResult<Car[], unknown> => {
  const { fetch } = useFetch();
  return useQuery("cars", () => getCars(fetch));
};

const useCarQuery = (id: number)
: UseQueryResult<Car, unknown> => {
  const { fetch } = useFetch();
  return useQuery(["cars", id], () => getCar(fetch, id));
};

export { useCarsQuery, useCarQuery };