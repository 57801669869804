import { AxiosInstance } from "axios";
import { Model } from "../../types/models";

const postAddModel = async (
  instance: AxiosInstance,
  values: Model
): Promise<unknown> => {
  const { data } = await instance.post("/models", values);
  return data;
};

export { postAddModel };