import { TestDrive } from "./TestDrive";

export const isYearValid = (testDrive: TestDrive, filterData: TestDrive, maxDate: Date): boolean => {
  return testDrive.date <= maxDate && testDrive.date >= filterData.date;
};

export const isNameValid = (testDrive: TestDrive, filterData: TestDrive): boolean => {
  return (`${testDrive.user.firstName.toLowerCase()} ${testDrive.user.lastName.toLowerCase()}`).includes(`${filterData.user.firstName?.toLowerCase()} ${filterData.user.lastName?.toLowerCase()}`);
};

export const isManufacturerValid = (testDrive: TestDrive, filterData: TestDrive): boolean => {
  return testDrive.car.model.manufacturer.includes(filterData.car.model.manufacturer);
};

export const isModelValid = (testDrive: TestDrive, filterData: TestDrive): boolean => {
  return testDrive.car.model.modelName.includes(filterData.car.model.modelName);
};