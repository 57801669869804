import { AxiosInstance } from "axios";
import { Model } from "../../types/models";

const patchModel = async (
  instance: AxiosInstance,
  values: Model
): Promise<unknown> => {
  const { data } = await instance.patch("/models", values);
  return data;
};

export { patchModel };