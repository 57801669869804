import React from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 150,
      maxWidth: 300,
    },
    chips: {
      display: "flex",
      flexWrap: "wrap",
    },
    chip: {
      margin: 2,
    },
    noLabel: {
      marginTop: theme.spacing(3),
    },
  }),
);

interface Props {
  label: string
  items: string[]
  handleSelect: (selected: string) => void
}

const SingleSelect = (props: Props): JSX.Element => {
  const selectItems = props.items;
  const classes = useStyles();
  const [itemName, setItemName] = React.useState<string>("");

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setItemName(event.target.value as string);
    props.handleSelect(event.target.value as string);
  };

  return (
    <div>
      <FormControl className={classes.formControl}>
        <InputLabel id="single-select-label">{props.label}</InputLabel>
        <Select
          labelId="simple-select-label"
          id="single-select"
          value={itemName}
          onChange={handleChange}
        >
          {selectItems.map((item) => (
            <MenuItem key={item} value={item}>
              {item}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export { SingleSelect };
