import { useMemo, useState } from "react";
import SimpleImageSlider from "react-simple-image-slider";
import { format, isValid } from "date-fns";
import { TextField } from "@material-ui/core";

import { Car } from "../../types/cars";
import { useCarQuery } from "../../api/queries/cars";
import * as Styled from "./CarDetails.styles";
import { MultipleSelect } from "../utility/MultipleSelect";
import { DatePicker } from "../utility/DatePicker";
import { Upholstery } from "../../types/upholsteries";
import { Seat } from "../../types/seats";
import { Accessory } from "../../types/accessory";
import { Equipment } from "../../types/equipment";

const carPlaceholder: Car = {
  id: 1,
  imageLinks: [
    "https://i.wpimg.pl/730x0/m.autokult.pl/autowp-ru-audi-80-quattr-267de5d.jpg",
    "https://i.pinimg.com/originals/b0/8b/fb/b08bfb5813f5879a425573f592517840.jpg",
    "https://1.bp.blogspot.com/-lrGbfrJr0SU/VmxQlA3h3OI/AAAAAAACRa8/sSVB5fZKftE/s1600/Audi-80%2B%252826%2529.jpg"
  ],
  carType: "sedan",
  equipment: {
    id: 1,
    accessories: [
      {
        id: 1,
        name:"podgrzewane siedzenia"
      },
      {
        id: 2,
        name: "klimatyzacja"
      }
    ],
    seat: {
      id: 1,
      color: "brown",
      upholsteryType: "leather",
      seatType: "sport"
    },
    upholstery: {
      id: 1,
      color: "black",
      upholsteryType: "leather"
    }
  },
  engine: {
    capacity: 1,
    fuelType: "diesel",
    id: 1,
    markIds: [1],
    model: "1.9 TDI",
    power: 105
  },
  model: {
    id: 1,
    mark: {
      id: 1,
      markName: "Audi",
    },
    modelImageLink: "https://i.wpimg.pl/730x0/m.autokult.pl/autowp-ru-audi-80-quattr-267de5d.jpg",
    modelName: "80"
  },
  color: "czerwony",
  productionDate: new Date ("03.03.1993"),
  description: "basically a tank looking like a car, best in class, super ecological as it won't break and therefore produce waste from that"
};

enum Mode { DISPLAY, EDIT }

const CarDetails = (): JSX.Element => {
  const { isLoading, isError, data: carData } = useCarQuery(1);
  const [car, setCar] = useState<Car>(carPlaceholder);
  const [equipment, setEquipment] = useState<Equipment>(car.equipment);
  const [seat, setSeat] = useState<Seat>(car.equipment.seat);
  const [upholstery, setUpholstery] = useState<Upholstery>(car.equipment.upholstery);
  const [accessories, setAccessories] = useState<Accessory[]>(car.equipment.accessories);
  const accessoriesToSelect = carPlaceholder.equipment.accessories;
  const [mode, setMode] = useState<Mode>(Mode.DISPLAY);

  if (isError) {
    <h1>Error</h1>;
  }

  useMemo(() => {
    if(carData !== undefined) setCar(carData);
    else setCar(carPlaceholder);
  }, [carData]);

  const handleEdit = () => {
    setMode(Mode.EDIT);
  };

  const handleFieldChange = (field: string) => (e: React.ChangeEvent<HTMLInputElement>) => { 
    setCar({...car, [field]: e.target.value}); 
  };

  const handleSelectAccessories = (selected: string []) => {
    setAccessories(accessoriesToSelect.filter(accessory => selected.includes(accessory.name)));
    const equipmentTemp = equipment;
    equipmentTemp.accessories = accessories;
    setEquipment(equipmentTemp);
  };

  const handleSeatChange = (field: string) => (e: React.ChangeEvent<HTMLInputElement>) => { 
    setSeat({...seat, [field]: e.target.value});
    const equipmentTemp = equipment;
    equipmentTemp.seat = seat;
    setEquipment(equipmentTemp);
  };

  const handleUpholsteryChange = (field: string) => (e: React.ChangeEvent<HTMLInputElement>) => { 
    setUpholstery({...upholstery, [field]: e.target.value});
    const equipmentTemp = equipment;
    equipmentTemp.upholstery = upholstery;
    setEquipment(equipmentTemp);
  };

  const handleChangeDate = (date: Date) =>{
    if(isValid(date)) {
      setCar({...car, productionDate: date});
    }
  };

  const handleSave = () => {
    const tempCar = car;
    tempCar.equipment.accessories = accessories;
    tempCar.equipment.seat = seat;
    tempCar.equipment.upholstery = upholstery;
    setCar(tempCar);
    setMode(Mode.DISPLAY);
  };

  const handleCancel = () => {
    setMode(Mode.DISPLAY);
  };
  
  const formattedDate = format(car.productionDate, "dd/MM/yyyy");
  const photosWithUrl = car.imageLinks.map(image => ({ url: image }));

  return (
    <>
      {isLoading ? (
        <Styled.Loading size={150} />
      ) : (
        <>
          {mode === Mode.DISPLAY
            ?
            <div>
              <Styled.ModelDataContainer>
                <Styled.ManufacturerText>
                  {car.model.mark.markName}
                </Styled.ManufacturerText>
                <Styled.ModelText>
                  {car.model.modelName}
                </Styled.ModelText>
              </Styled.ModelDataContainer>
              <Styled.CarDetailsContainer>
                <Styled.CarDataContainer>
                  <Styled.CarDataHeading>
                    Car details
                  </Styled.CarDataHeading>
                  <Styled.CarData>
                    <Styled.CarDataTextContainer>
                      <Styled.CarDataText>
                        Type: {car.carType}
                      </Styled.CarDataText>
                      <Styled.CarDataText>
                        Accessories: {car.equipment.accessories.map(accessory => accessory.name).join(", ")}
                      </Styled.CarDataText>
                      <Styled.CarDataText>
                        Seats:
                        <Styled.CarDataSubText>
                          Color: {car.equipment.seat.color}
                        </Styled.CarDataSubText>
                        <Styled.CarDataSubText>
                          Seat type: {car.equipment.seat.seatType}
                        </Styled.CarDataSubText>
                        <Styled.CarDataSubText>
                          Material: {car.equipment.seat.upholsteryType}
                        </Styled.CarDataSubText>
                      </Styled.CarDataText>
                      <Styled.CarDataText>
                        Upholstery:
                        <Styled.CarDataSubText>
                          Color: {car.equipment.upholstery.color}
                        </Styled.CarDataSubText>
                        <Styled.CarDataSubText>
                          Material: {car.equipment.upholstery.upholsteryType}
                        </Styled.CarDataSubText>
                      </Styled.CarDataText>
                      <Styled.CarDataText>
                        Engine: {car.engine.model}
                      </Styled.CarDataText>
                      <Styled.CarDataText>
                        Color: {car.color}
                      </Styled.CarDataText>
                      <Styled.CarDataText>
                        Date of production: {formattedDate}
                      </Styled.CarDataText>
                    </Styled.CarDataTextContainer>
                    <Styled.CarDescriptionText>
                      {car.description}
                    </Styled.CarDescriptionText>
                  </Styled.CarData>
                </Styled.CarDataContainer>
                <Styled.CarPhotosContainer>
                  <Styled.CarPhotosHeading>
                    Car pictures
                  </Styled.CarPhotosHeading>
                  <Styled.PhotosContainer>
                    <SimpleImageSlider
                      width={400}
                      height={400}
                      showNavs
                      showBullets
                      images={photosWithUrl}/>
                  </Styled.PhotosContainer>
                </Styled.CarPhotosContainer>
              </Styled.CarDetailsContainer>
              <Styled.OptionsContainer>
                <Styled.StyledButton
                  variant="contained" 
                  color="primary"
                  onClick={handleEdit}>
                  Edit
                </Styled.StyledButton>
                <Styled.StyledButton variant="contained" color="primary">
                  Reservations
                </Styled.StyledButton>
              </Styled.OptionsContainer>
            </div>
            :
            <div>
              <Styled.ModelDataContainer>
                <Styled.ManufacturerText>
                  {car.model.mark.markName}
                </Styled.ManufacturerText>
                <Styled.ModelText>
                  {car.model.modelName}
                </Styled.ModelText>
              </Styled.ModelDataContainer>
              <Styled.CarDetailsContainer>
                <Styled.CarDataContainer>
                  <Styled.CarDataHeading>
                    Car details
                  </Styled.CarDataHeading>
                  <Styled.CarData>
                    <Styled.CarDataTextContainer>
                      <Styled.CarDataEditText>
                        <TextField
                          label="Type"
                          onChange={handleFieldChange("carType")}
                          defaultValue={car.carType}
                        />
                      </Styled.CarDataEditText>
                      <Styled.CarDataEditSelect>
                        <MultipleSelect
                          label={"Accessories"}
                          items={accessoriesToSelect.map(accessory => accessory.name)} 
                          handleSelect={handleSelectAccessories}
                        />
                      </Styled.CarDataEditSelect>
                      <Styled.CarDataText>
                        Seats:
                        <Styled.CarDataSubEditText>
                          <TextField
                            label="Color"
                            onChange={handleSeatChange("color")}
                            defaultValue={car.equipment.seat.color}
                          />
                        </Styled.CarDataSubEditText>
                        <Styled.CarDataSubEditText>
                          <TextField
                            label="Seat type"
                            onChange={handleSeatChange("seatType")}
                            defaultValue={car.equipment.seat.seatType}
                          />
                        </Styled.CarDataSubEditText>
                        <Styled.CarDataSubEditText>
                          <TextField
                            label="Material"
                            onChange={handleSeatChange("upholsteryType")}
                            defaultValue={car.equipment.seat.upholsteryType}
                          />
                        </Styled.CarDataSubEditText>
                      </Styled.CarDataText>
                      <Styled.CarDataText>
                        Upholstery:
                        <Styled.CarDataSubEditText>
                          <TextField
                            label="Color"
                            onChange={handleUpholsteryChange("color")}
                            defaultValue={car.equipment.upholstery.color}
                          />
                        </Styled.CarDataSubEditText>
                        <Styled.CarDataSubEditText>
                          <TextField
                            label="Material"
                            onChange={handleUpholsteryChange("upholsteryType")}
                            defaultValue={car.equipment.upholstery.upholsteryType}
                          />
                        </Styled.CarDataSubEditText>
                      </Styled.CarDataText>
                      <Styled.CarDataEditText>
                        <TextField
                          label="Engine"
                          onChange={handleFieldChange("engine.model")}
                          defaultValue={car.engine.model}
                        />
                      </Styled.CarDataEditText>
                      <Styled.CarDataEditText>
                        <TextField
                          label="Color"
                          onChange={handleFieldChange("color")}
                          defaultValue={car.color}
                        />
                      </Styled.CarDataEditText>
                      <Styled.CarDataEditText>
                        <DatePicker
                          date={car.productionDate}
                          handleDateChange={handleChangeDate}
                          label="Production date"
                        />
                      </Styled.CarDataEditText>
                    </Styled.CarDataTextContainer>
                    <Styled.CarDescriptionEditText>
                      <TextField
                        fullWidth
                        multiline
                        label="Description"
                        onChange={handleFieldChange("description")}
                        defaultValue={car.description}
                      />
                    </Styled.CarDescriptionEditText>
                  </Styled.CarData>
                </Styled.CarDataContainer>
                <Styled.CarPhotosContainer>
                  <Styled.CarPhotosHeading>
                    Car pictures
                  </Styled.CarPhotosHeading>
                  <Styled.PhotosContainer>
                    <SimpleImageSlider
                      width={400}
                      height={400}
                      showNavs
                      showBullets
                      images={photosWithUrl}
                    />
                  </Styled.PhotosContainer>
                </Styled.CarPhotosContainer>
              </Styled.CarDetailsContainer>
              <Styled.OptionsContainer>
                <Styled.StyledButton 
                  variant="contained" 
                  color="primary"
                  onClick={handleSave}>
                  Save
                </Styled.StyledButton>
                <Styled.StyledButton 
                  variant="contained" 
                  color="primary"
                  onClick={handleCancel}>
                  Cancel
                </Styled.StyledButton>
                <Styled.StyledButton variant="contained" color="primary">
                  Reservations
                </Styled.StyledButton>
              </Styled.OptionsContainer>
            </div>
          }
        </>
      )}
    </>
  );
};

export { CarDetails };