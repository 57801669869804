import styled from "styled-components";

const ModelContainer = styled.div`
    flex: 1;
    padding: 10px 15px;
    background: ${({theme}) => theme.palette.background.default};
    margin: 15px 25px;
    width: 400px;
    height: 350px;
`;

const EditButton = styled.button`
`;

const DeleteButton = styled.button`
`;

export { ModelContainer, EditButton, DeleteButton };