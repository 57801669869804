import styled from "styled-components";
import CircularProgress from "@material-ui/core/CircularProgress";

const ModelsTable = styled.div`
    display: flex;
    flex-direction: column;
    padding: 15px 10px;
    background: ${({theme}) => theme.palette.background.default};
    margin: 20px 15px;
`;

const MarkContainer = styled.div`
    padding: 15px 10px;
    background: ${({theme}) => theme.palette.background.default};
    margin: 20px 15px;
`;

const MarkModels = styled.div`
    display: flex;
    flex-wrap: wrap;
    columns: 2;
    padding: 15px 10px;
    background: ${({theme}) => theme.palette.background.default};
    margin: 20px 15px;
`;

const AddButton = styled.button`
    flex: 1;
    width: 40px;
`;

const OptionsContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: 65vw;
`;

const MarkHeading = styled.p`
    color: #666;
    font-weight: 300;
    font-size: 34px;
    letter-spacing: 3px;
    border-bottom: 2px solid ${({theme}) => theme.palette.outline.grey};
    position: relative;
    right: 10vw;
    width: 70vw;
`;

const Loading = styled(CircularProgress)`
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -75px;
`;

export {
  ModelsTable,
  MarkContainer,
  MarkModels,
  AddButton,
  OptionsContainer,
  MarkHeading,
  Loading
};