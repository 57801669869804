import React, { useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import { TextField } from "@material-ui/core";

import * as Styled from "../Modal.styles";
import { User } from "./User";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: "absolute",
      width: 400,
      backgroundColor: theme.palette.background.paper,
      border: "2px solid #000",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  }),
);

interface UserModalProps {
    open: boolean
    label: string
    user: User
    handleApply: (user: User) => void
    handleClose: () => void
}

const UserModal = (props: UserModalProps): JSX.Element => {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const [user, setUser] = useState<User>(props.user);

  const handleFieldChange = (field: string) => (e: React.ChangeEvent<HTMLInputElement>) => { 
    setUser({...user, [field]: e.target.value});
  };

  const handleApply = () => {
    props.handleApply(user);
  };

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <h2>{props.label}</h2>
      <Styled.ModelModalData>
        <TextField 
          label="First Name" 
          value={user?.firstName}
          onChange={handleFieldChange("firstName")}>
        </TextField>
        <TextField 
          label="Last Name" 
          value={user?.lastName}
          onChange={handleFieldChange("lastName")}>
        </TextField>
        <TextField 
          label="Email" 
          value={user?.email}
          onChange={handleFieldChange("email")}>
        </TextField>
        <TextField 
          label="Phone Number" 
          value={user?.phoneNumber}
          onChange={handleFieldChange("phoneNumber")}>
        </TextField>
        <TextField 
          label="Photo URL" 
          value={user?.photo}
          onChange={handleFieldChange("photo")}>
        </TextField>
      </Styled.ModelModalData>
      <Styled.ModelModalButtons>
        <Styled.AddButton
          variant="contained"
          color="primary"
          onClick={handleApply}>
            Apply
        </Styled.AddButton>
        <Styled.CancelButton
          variant="contained"
          color="primary"
          onClick={() => props.handleClose()}>
            Cancel
        </Styled.CancelButton>
      </Styled.ModelModalButtons>
    </div>
  );

  return (
    <div>
      <Modal
        open={props.open}
        onClose={() => props.handleClose()}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  );
};

export { UserModal };