

const validateEmail = (email :string) :boolean =>{
  const regexp = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
  return regexp.test(email);
};

const validatePassword = (password :string) :boolean =>{
  return password.length >= 5 && password.length <= 20 ? true : false ;
};

const validateInputLenght = (input: string): boolean=>{
  return input.length > 0 ;
};

export  {validateEmail, validatePassword, validateInputLenght};