import { AxiosInstance } from "axios";

export const getModels = async (instance: AxiosInstance): Promise<unknown> => {
  const { data } = await instance.get("/models");
  return data;
};

export const getModel = async (instance: AxiosInstance, id: number): Promise<unknown> => {
  const { data } = await instance.get(`/models/${id}`);
  return data;
};

export const getCars = async (instance: AxiosInstance): Promise<unknown> => {
  const { data } = await instance.get("/cars");
  return data;
};
  
export const getCar = async (instance: AxiosInstance, id: number): Promise<unknown> => {
  const { data } = await instance.get(`/cars/${id}`);
  return data;
};

export const getUsers = async (instance: AxiosInstance): Promise<unknown> => {
  const { data } = await instance.get("/users");
  return data;
};

export const getUser = async (instance: AxiosInstance, id: number): Promise<unknown> => {
  const { data } = await instance.get(`/users/${id}`);
  return data;
};

export const getTestDrives = async (instance: AxiosInstance): Promise<unknown> => {
  const { data } = await instance.get("/test-drives");
  return data;
};

export const getTestDrive = async (instance: AxiosInstance, id: number): Promise<unknown> => {
  const { data } = await instance.get(`/test-drives/${id}`);
  return data;
};

export const getEngines = async (instance: AxiosInstance): Promise<unknown> => {
  const { data } = await instance.get("/engines");
  return data;
};

export const getEngine = async (instance: AxiosInstance, id: number): Promise<unknown> => {
  const { data } = await instance.get(`/engines/${id}`);
  return data;
};

export const getEquipments = async (instance: AxiosInstance): Promise<unknown> => {
  const { data } = await instance.get("/equipments");
  return data;
};

export const getEquipment = async (instance: AxiosInstance, id: number): Promise<unknown> => {
  const { data } = await instance.get(`/equipments/${id}`);
  return data;
};

export const getSeats = async (instance: AxiosInstance): Promise<unknown> => {
  const { data } = await instance.get("/seats");
  return data;
};

export const getSeat = async (instance: AxiosInstance, id: number): Promise<unknown> => {
  const { data } = await instance.get(`/seats/${id}`);
  return data;
};

export const getUpholsteries = async (instance: AxiosInstance): Promise<unknown> => {
  const { data } = await instance.get("/upholsteries");
  return data;
};

export const getUpholstery = async (instance: AxiosInstance, id: number): Promise<unknown> => {
  const { data } = await instance.get(`/upholsteries/${id}`);
  return data;
};

export const getMarks = async (instance: AxiosInstance): Promise<unknown> => {
  const { data } = await instance.get("/marks");
  return data;
};

export const getMark = async (instance: AxiosInstance, id: number): Promise<unknown> => {
  const { data } = await instance.get(`/marks/${id}`);
  return data;
};