import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import React, {useState} from "react";
import * as Styled from "./Login.style";
import InputAdornment from "@material-ui/core/InputAdornment";
import AccountCircle from "@material-ui/icons/AccountCircle";
import LockOpenRoundedIcon from "@material-ui/icons/LockOpenRounded";
import Button from "@material-ui/core/Button";
import Logo from "../../assets/logoMotorShow.png";
import { validateEmail, validatePassword } from "./Validations";

interface Props {
  // eslint-disable-next-line @typescript-eslint/ban-types
  setIsOpenLoginWindow: Function
  // eslint-disable-next-line @typescript-eslint/ban-types
  handleClose: Function
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      "& .MuiTextFiel+d-root": {
        margin: theme.spacing(1),
        width: 200
      },
    },
  }),
);

const LoginForm = (props: Props): JSX.Element => {

  const classes = useStyles();
  const [email,setEmail] = useState<string>("");
  const [password,setPassword] = useState<string>("");

  const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handleChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  };

  const handleLogin = () => {
    //For this moment there is no endpoint for login process.
    props.handleClose();
  };

  return (
    <form className={classes.root}>
      <Styled.LoginFormContainer>
        <Styled.Container>
          <img src = {Logo}/>
        </Styled.Container>
        <Styled.Container>
          <Styled.Text
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccountCircle />
                </InputAdornment>
              ),
            }}
            error = {!validateEmail(email)}
            id="email-input"
            label="Email"
            variant="outlined"
            value = {email}
            onChange = {handleChangeEmail}
          />
        </Styled.Container>
        <Styled.Container>
          <Styled.Text
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LockOpenRoundedIcon />
                </InputAdornment>
              ),
            }}
            error = {!validatePassword(password)}
            id="password-input"
            label="Password"
            variant="outlined"
            type="password"
            value = {password}
            onChange = {handleChangePassword}
          />
        </Styled.Container>
        <Button variant="contained" color="primary" onClick = {() => handleLogin()}>
           Login
        </Button>
        <Styled.Container>
          <h6>You do not have an account ? <Styled.TextLink onClick = {() => props.setIsOpenLoginWindow(false)} >Sign up</Styled.TextLink> </h6>
        </Styled.Container>
      </Styled.LoginFormContainer>
      
    </form>
  );
}; 

export default LoginForm;