import { useState, ChangeEvent } from "react";
import TextField from "@material-ui/core/TextField";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import { Add } from "@material-ui/icons";
import { StylesProvider } from "@material-ui/core/styles";

import * as FilterStyles from "../Filter.styles";
import * as TableStyles from "../Table.styles";
import { User } from "./User";
import { UserModal } from "./UserModal";
import { UserArea } from "./UserArea";


const userSet: User[] = [
  {
    id: 1,
    firstName: "Adam",
    lastName: "Malysz",
    email: "abc@abc.com",
    phoneNumber: "123123123",
    photo: "https://media.thetab.com/blogs.dir/90/files/2020/10/eiddclywsamofb9-819x1024.jpeg",
  },
  {
    id: 2,
    firstName: "Mariusz",
    lastName: "Pudzianowski",
    email: "cba@cba.com",
    phoneNumber: "321321321",
    photo: "https://media.thetab.com/blogs.dir/90/files/2020/10/eiddclywsamofb9-819x1024.jpeg",
  },
  {
    id: 3,
    firstName: "Aaa",
    lastName: "Bbbbb",
    email: "bca@bca.com",
    phoneNumber: "132132132",
    photo: "https://media.thetab.com/blogs.dir/90/files/2020/10/eiddclywsamofb9-819x1024.jpeg",
  },
  {
    id: 4,
    firstName: "Adam",
    lastName: "Malysz",
    email: "abc@abc.com",
    phoneNumber: "123123123",
    photo: "https://media.thetab.com/blogs.dir/90/files/2020/10/eiddclywsamofb9-819x1024.jpeg",
  },
  {
    id: 5,
    firstName: "Mariusz",
    lastName: "Pudzianowski",
    email: "cba@cba.com",
    phoneNumber: "321321321",
    photo: "https://media.thetab.com/blogs.dir/90/files/2020/10/eiddclywsamofb9-819x1024.jpeg",
  },
  {
    id: 6,
    firstName: "Aaa",
    lastName: "Bbbbb",
    email: "bca@bca.com",
    phoneNumber: "132132132",
    photo: "https://media.thetab.com/blogs.dir/90/files/2020/10/eiddclywsamofb9-819x1024.jpeg",
  }
];

const Userbase = (): JSX.Element => {
  const [users, setUsers] = useState<User[]>(userSet);
  const [filterData, setFilterData] = useState<User>({
    id: 0,
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    photo: "",
  });
  const [selectedUsers, setSelectedUsers] = useState<User[]>(users);
  const [modalAddOpen, setModalAddOpen] = useState<boolean>(false);

  const handleFilter = () => {
    const newUsers = userFilter(users);
    setSelectedUsers(newUsers);
  };

  const filterUser = (user: User, field: string) => { 
    return user[field].toString().toLowerCase().includes(filterData[field].toString().toLowerCase()); 
  };

  const userFilter = (users: User[]) => {
    return users.filter(user => {
      const fields = ["firstName", "lastName", "phoneNumber", "email"];
      return !fields.some(field => !filterUser(user, field));
    });
  };

  const handleFieldChange = (field: string) => (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setFilterData({...filterData, [field]: event.target.value});
  };

  const handleChange = (row: User) => {
    const newUsers = users.filter(user => user.id != row.id);
    setUsers(newUsers);
    setSelectedUsers(newUsers);
  };

  const handleDelete = (row: User) => {
    const newUsers = users.filter(user => user.id != row.id);
    setUsers(newUsers);
    setSelectedUsers(newUsers);
  };

  const handleCloseAddModal = () => {
    setModalAddOpen(false);
  };

  const handleApplyAddModal = () => {
    setModalAddOpen(false);
  };

  return(
    <StylesProvider injectFirst>
      <div className="Userbase">
        <FilterStyles.FilterHeading>
          Filter by details
        </FilterStyles.FilterHeading>
        <FilterStyles.FilterLine/>
        <FilterStyles.TextfieldContainer>
          <TextField
            label="First name"
            onChange={handleFieldChange("firstName")}/>
          <TextField
            label="Last name"
            onChange={handleFieldChange("lastName")}/>
          <TextField
            label="Phone"
            onChange={handleFieldChange("phoneNumber")}/>
          <TextField
            label="Email"
            onChange={handleFieldChange("email")}/>
        </FilterStyles.TextfieldContainer>
        <FilterStyles.FilterButton
          variant="contained"
          color="primary"
          onClick={handleFilter}>
          Apply
        </FilterStyles.FilterButton>
        <FilterStyles.AddButton onClick={() => setModalAddOpen(true)}>
          <Add/>
        </FilterStyles.AddButton>
        <UserModal
          open={modalAddOpen}
          label={"Add new user"}
          user = {{
            id: 0,
            firstName: "",
            lastName: "",
            email: "",
            phoneNumber: "",
            photo: "",
          }}
          handleApply={handleApplyAddModal}
          handleClose={handleCloseAddModal}
        />
        <FilterStyles.FilterLine/>
        <TableStyles.StyledTable>
          <Table>
            <TableHead>
              <TableRow>
                <TableStyles.TableHeadRow>Photo</TableStyles.TableHeadRow>
                <TableStyles.TableHeadRow>Name</TableStyles.TableHeadRow>
                <TableStyles.TableHeadRow>Phone</TableStyles.TableHeadRow>
                <TableStyles.TableHeadRow>Email</TableStyles.TableHeadRow>
                <TableStyles.TableHeadRow>Actions</TableStyles.TableHeadRow>
              </TableRow>
            </TableHead>
            <TableBody>
              {selectedUsers.map((row) => (
                <UserArea 
                  key={row.id}
                  user={row}
                  handleChange={handleChange}
                  handleDelete={handleDelete}
                />
              ))}
            </TableBody>
          </Table>
        </TableStyles.StyledTable>
      </div>
    </StylesProvider>
  );
};

export { Userbase };