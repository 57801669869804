import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import styled from "styled-components";
import { CarDetails } from "./components/car_details/CarDetails";
import { Authorization } from "./components/authorization/Authorization";
import { AppMenu } from "./components/menu/AppMenu";
import { ModelCatalog } from "./components/models/ModelCatalog";
import { TestDrivesBase } from "./components/test_drives/TestDrivesBase";
import { Userbase } from "./components/users/Userbase";
import { FetchProvider } from "./components/providers/FetchProvider";
import { CarBase } from "./components/cars/CarBase";
import { AccountScreen } from "./components/account/AccountScreen";

const AppContainer = styled.div`
  width: 1000px;
  margin: 0 auto;
`;

const queryClient = new QueryClient();

const App = (): JSX.Element =>  {

  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        <FetchProvider>
          <Router>
            <AppMenu/>
            <AppContainer>
              <Switch>
                <Route exact path = "/models">
                  <ModelCatalog/>
                </Route>
                <Route path = "/test-drives">
                  <TestDrivesBase/>
                </Route>
                <Route path = "/users">
                  <Userbase/>
                </Route>
                <Route path = "/models/:modelId">
                  <CarBase/>
                </Route>
                <Route path = "/cars/:id">
                  <CarDetails/>
                </Route>
                <Route path = "/account">
                  <AccountScreen/>
                </Route>
                <Route path = "/authorization">
                  <Authorization />
                </Route>
              </Switch>
            </AppContainer>
          </Router>
        </FetchProvider>
      </QueryClientProvider>
    </div>
  );
};

export default App;
